import * as React from "react"
import { Helmet } from "react-helmet";
import * as index_styles from '../styles/index.module.css'

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Home | Jim Wu</title>
      </Helmet>
      <main>
        <div className={index_styles.container}>
          
        <div className={index_styles.toc}>
          <div className={index_styles.c}><a href='/'>home</a></div>
          {/* <div className={index_styles.c}><a href='/writing'>writing</a></div> */}
          <div className={index_styles.c}><a href='mailto:jim.wu6@outlook.com'>email</a></div>
          <div className={index_styles.c}><a href='resume.pdf'>resumé</a></div>
        </div>

        <div>
        I work on efficient training and inference of video models at <a href='https://genmo.ai'>Genmo</a>, and study Computer Science at the <a href='https://cs.uwaterloo.ca'>University of Waterloo</a>.
        </div>

        <br></br>

        <div>
        Previously, I did research on machine learning performance optimizations like speculative decoding and sparsity
        at <a href='https://www.databricks.com/research/mosaic'>Mosaic Resesarch</a>/<a href='https://databricks.com'>Databricks</a>.
        I used to be a software engineer at <a href='https://tesla.com'>Tesla Autopilot</a>, 
        researching efficient quantization such as FP8 on large vision models along with improving the training systems on Dojo. 
        Before that, I worked on Large Language Models at <a href='https://cohere.com'>Cohere</a> as an early engineer,
        focusing on model inference and machine learning systems.
        </div>

        <br></br>

        <div>
        
        </div>

        <div>
          My academic interests include Machine Learning
          (especially in Deep Learning training dynamics, performance, and NLP),
          hard software problems in Distributed Systems and PL/Compilers, and
          Education (broadly and specifically in CS and Math).
        </div>

        <br></br>

        <div>
        Other things that take up my time include <a href='https://goodreads.com/jimwu'>reading</a>, 
        learning about the histories and philosophies of both science and art,
        crosswords,
        exercise (nowadays lifting, running, and swimming).
        {/* Writing more, hosting dinner parties, and learning watercolour painting are also in the near-future. */}
        </div>

        <br></br>

        </div>
    </main>

    </div>
    
  )
}


export default IndexPage
